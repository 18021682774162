export default [
    {
        id_campaign: 'ca_qEmqLQwAHuU3R5RrK1',
        active: true,
        name: 'Verano Vibrante en PlazaVea: Descubre Nuestras Promociones Especiales',
        type: 'Boleta electrónica',
        startDate: '14 de dic de 2023',
        endDate: '25 de dic 2023',
        env: '3.245.567',
        rec: '2.563.189',
        abto: '2.134.654',
        clicks: '1.456.432',
        reb: '682.362',
        spam: '16',
        panel: [
            {
                status: 'Enviado',
                count: 100,
                type: 'Boleta elec.'
            },
            {
                status: 'Recibido',
                count: 98,
                rate: '98%'
            },
            {
                status: 'Abierto',
                count: 93,
                rate: '93%'
            },
            {
                status: 'Cliqueado',
                count: 82,
                rate: '82%'
            },
            {
                status: 'Rebotado',
                count: 1,
                rate: '1%'
            },
            {
                status: 'SPAM',
                count: 1,
                rate: '1%'
            }
        ],
        statistics: [
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Abierto',
                open: 2,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Abierto',
                open: 1,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Recibido',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'SPAM',
                open: '-',
                clicks: '-'
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Rebotado',
                open: '-',
                clicks: '-'
            }
        ]
    },
    {
        id_campaign: 'ca_qEmqLQwAHuU3R5RrK4',
        active: true,
        name: 'Solo con los verdaderos precios bajos, llenas así tu carrito ¡Y sin ofertas!',
        type: 'Boleta electrónica',
        startDate: '01 de mar de 2024',
        endDate: '31 de may de 2024',
        env: '3.245.567',
        rec: '2.563.189',
        abto: '2.134.654',
        clicks: '1.456.432',
        reb: '682.362',
        spam: '16',
        panel: [
            {
                status: 'Enviado',
                count: 60,
                type: 'Boleta elec.'
            },
            {
                status: 'Recibido',
                count: 58,
                rate: '98%'
            },
            {
                status: 'Abierto',
                count: 43,
                rate: '93%'
            },
            {
                status: 'Cliqueado',
                count: 58,
                rate: '82%'
            },
            {
                status: 'Rebotado',
                count: 1,
                rate: '1%'
            },
            {
                status: 'SPAM',
                count: 1,
                rate: '1%'
            }
        ],
        statistics: [
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Abierto',
                open: 1,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Recibido',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            }
        ]
    },
    {
        id_campaign: 'ca_qEmqLQwAHuU3R5RrK5',
        active: true,
        name: 'Marzo Fresco en PlazaVea: Descubre Nuestras Ofertas de Fin de Temporada',
        type: 'Boleta electrónica',
        startDate: '01 de mar de 2024',
        endDate: '31 de mar 2024 ',
        env: '1.942.153',
        rec: '1.705.535',
        abto: '1.312.864',
        clicks: '1.124.748',
        reb: '236.600',
        spam: '18',
        panel: [
            {
                status: 'Enviado',
                count: 100,
                type: 'Boleta elec.'
            },
            {
                status: 'Recibido',
                count: 98,
                rate: '98%'
            },
            {
                status: 'Abierto',
                count: 93,
                rate: '93%'
            },
            {
                status: 'Cliqueado',
                count: 82,
                rate: '82%'
            },
            {
                status: 'Rebotado',
                count: 1,
                rate: '1%'
            },
            {
                status: 'SPAM',
                count: 1,
                rate: '1%'
            }
        ],
        statistics: [
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Abierto',
                open: 2,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Abierto',
                open: 1,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'SPAM',
                open: '-',
                clicks: '-'
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Recibido',
                open: 0,
                clicks: 0
            }
        ]
    },
    {
        id_campaign: 'ca_qEmqLQwAHuU3R5RrK3',
        active: true,
        name: '¡Celebra el Verano con Ofertas Frescas y Sabrosas!',
        type: 'Boleta electrónica',
        startDate: '01 de mar de 2024',
        endDate: '31 de may de 2024',
        env: '3.245.567',
        rec: '2.563.189',
        abto: '2.134.654',
        clicks: '1.456.432',
        reb: '682.362',
        spam: '16',
        panel: [
            {
                status: 'Enviado',
                count: 90,
                type: 'Boleta elec.'
            },
            {
                status: 'Recibido',
                count: 88,
                rate: '98%'
            },
            {
                status: 'Abierto',
                count: 83,
                rate: '93%'
            },
            {
                status: 'Cliqueado',
                count: 72,
                rate: '82%'
            },
            {
                status: 'Rebotado',
                count: 1,
                rate: '1%'
            },
            {
                status: 'SPAM',
                count: 0,
                rate: '0%'
            }
        ],
        statistics: [
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Abierto',
                open: 2,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Recibido',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'SPAM',
                open: '-',
                clicks: '-'
            }
        ]
    },
    {
        id_campaign: 'ca_qEmqLQwAHuU3R5Rru2',
        active: false,
        name: 'PlazaVea en San Valentín: Encuentra el Regalo Perfecto con Nuestras Ofertas Especiales',
        type: 'Boleta electrónica',
        startDate: '01 de feb de 2024',
        endDate: '14 de feb 2024 ',
        env: '1.942.153',
        rec: '1.705.535',
        abto: '1.312.864',
        clicks: '1.124.748',
        reb: '236.600',
        spam: '18',
        panel: [
            {
                status: 'Enviado',
                count: 100,
                type: 'Boleta elec.'
            },
            {
                status: 'Recibido',
                count: 98,
                rate: '98%'
            },
            {
                status: 'Abierto',
                count: 93,
                rate: '93%'
            },
            {
                status: 'Cliqueado',
                count: 82,
                rate: '82%'
            },
            {
                status: 'Rebotado',
                count: 1,
                rate: '1%'
            },
            {
                status: 'SPAM',
                count: 1,
                rate: '1%'
            }
        ],
        statistics: [
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Abierto',
                open: 2,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Abierto',
                open: 1,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'SPAM',
                open: '-',
                clicks: '-'
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Recibido',
                open: 0,
                clicks: 0
            }
        ]
    },
    {
        id_campaign: 'ca_qEmqLQwAHuU3R5R233',
        active: false,
        name: 'Carnaval de Ahorros en PlazaVea: Descubre Nuestras Promociones para la Fiesta',
        type: 'Boleta electrónica',
        startDate: '15 de feb de 2023',
        endDate: '31 de en de 2024',
        env: '3.245.567',
        rec: '2.563.189',
        abto: '2.134.654',
        clicks: '1.456.432',
        reb: '682.362',
        spam: '16',
        panel: [
            {
                status: 'Enviado',
                count: 90,
                type: 'Boleta elec.'
            },
            {
                status: 'Recibido',
                count: 88,
                rate: '98%'
            },
            {
                status: 'Abierto',
                count: 83,
                rate: '93%'
            },
            {
                status: 'Cliqueado',
                count: 72,
                rate: '82%'
            },
            {
                status: 'Rebotado',
                count: 1,
                rate: '1%'
            },
            {
                status: 'SPAM',
                count: 0,
                rate: '0%'
            }
        ],
        statistics: [
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Abierto',
                open: 2,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Recibido',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'Enviado',
                open: 0,
                clicks: 0
            },
            {
                email: 'marialopez@construccionesdelsur.com',
                name: 'Maria Teres Lopez O.',
                sendDate: '29 sep. 2022 12:20',
                status: 'SPAM',
                open: '-',
                clicks: '-'
            }
        ]
    }
]