<template>
  <v-row>
    <!-- header -->
    <mini-header :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true">
      <template v-slot:main>
        <v-btn :to="{ name: 'TemplateList' }" color="primary"><v-icon left>mdi-pencil-ruler</v-icon>Crear nueva plantilla</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-col class="mt-0 px-0">
      <v-row>
        <v-col v-for="statistic in statistics" :key="statistic.status">
          <v-card class="align-center d-flex px-3 py-3" flat>
            <v-sheet class="transparent mt-3 mb-4">
              <span class="secondary--text body-2 d-flex align-center">
                <v-icon class="mr-2" color="secondary" small>{{status.find(({key}) => statistic.status === key)?.icon}}</v-icon>
                {{ status.find(({key}) => statistic.status === key)?.key }}
              </span>
              <span class="d-block h4 font-weight-bold mt-2" :class="`${status.find(({key}) => statistic.status === key)?.colorText}--text`">{{statistic.count}}</span>
              <span class="d-block pt-1 body-2 secondary--text" v-if="statistic.docs">Documentos:
                <span class="font-weight-bold body-2">{{ statistic.docs }}</span>
              </span>
              <span class="d-block body-2 secondary--text" v-else> Tasa de entrega:
                <span class="font-weight-bold">{{statistic.rate}}</span>
              </span>
            </v-sheet>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mt-5" flat>
        <v-card-text class="py-5 px-4">
          <v-row>
            <v-col class="d-flex align-center body-2 fontBody--text" cols="2" v-for="statistic in statistics" :key="statistic.status">
              <v-avatar class="mr-2" :color="status.find(({key}) => statistic.status === key)?.colorText" size="12"></v-avatar> {{ status.find(({key}) => statistic.status === key)?.key }}
            </v-col>
          </v-row>
        </v-card-text>
        <div class="mx-auto pa-5">
          <chart-line
            axe-type-y="number"
            :background-color="'transparent'"
            :chart-data="receivedChartData"
            chart-id="documentsIssued"
            :number-rounded="true"
            :show-axe-x="true"
            :showAxeY="true"
            :show-draw-border-grid-lines-axe-y="true"
            :show-grid-lines-axe-y="true"
            :show-point="true"
            :borderWidth="1"
            :height="300"
            :hiddenTootltip="true"
            style="position:relative; height: 100%"
          />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import campaign from '@/data/campaignList' // example
import ChartLine from '@/components/charts/ChartLine'
import MiniHeader from '@/components/commons/MiniHeader'
import VEmptyState from '@/components/commons/VEmptyState'
import GenericViewMixin from '@/mixins/GenericViewMixin'

export default {
  components: {
    MiniHeader,
    VEmptyState,
    ChartLine
  },
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    campaignList: campaign,
    breadcrumbs: {
     main: 'Plantillas',
     children: [{ text: 'Monitor'}]
   },
   statistics: [
    {
        status: 'Enviados',
        count: 27.804,
        docs: 12.529
    },
    {
        status: 'Recibidos',
        count: 27.804,
        rate: '96%'
    },
    {
        status: 'Abiertos',
        count: 25.433,
        rate: '88%'
    },
    {
        status: 'Cliqueados',
        count: 14.368,
        rate: '49%'
    },
    {
        status: 'Rebotados',
        count: 958,
        rate: '3%'
    },
    {
        status: 'SPAM',
        count: 147,
        rate: '0,5'
    }
  ],
  status: [
    {
      key: 'Enviados',
      code: 'send',
      icon: 'mdi-email-fast',
      color: 'lightBlue',
      colorText: 'primary'
    },
    {
      key: 'Recibidos',
      code: 'received',
      icon: 'mdi-email-check',
      color: 'yellowLight',
      colorText: 'yellow'
    },
    {
      key: 'Abiertos',
      code: 'open',
      icon: 'mdi-email-open',
      color: 'greenExtraLight',
      colorText: 'success'
    },
    {
      key: 'Rebotados',
      code: 'reboot',
      icon: 'mdi-email-arrow-left',
      color: 'blueGreyLighten',
      colorText: 'fontBody'
    },
    {
      key: 'Cliqueados',
      icon: 'mdi-cursor-default-click',
      color: 'purpleLight',
      colorText: 'purpleMedium'
    },
    {
      key: 'SPAM',
      code: 'spam',
      icon: 'mdi-email-remove',
      color: 'redLight',
      colorText: 'redMedium'
    }
  ],
  receivedChartData: {
    labels: [
      "Miércoles",
      "Lunes",
      "Domingo"
    ],
    datasets: [
      {
        label: "Enviados",
        backgroundColor: '#0352CC',
        percent_change: '20.84',
        data: [27804, 10804, 25804]
      },
      {
        label: "Recibidos",
        backgroundColor: '#E8A803',
        percent_change: '-90.34',
        data: [30800, 16800, 15800]
      },
      {
        label: "Abiertos",
        backgroundColor: '#88B437',
        percent_change: '-90.34',
        data: [25433, 25800, 22000]
      },
      {
        label: "Cliqueados",
        backgroundColor: '#81007F',
        percent_change: '-90.34',
        data: [14368, 10000, 20433]
      },
      {
        label: "Rebotados",
        backgroundColor: '#455A64',
        percent_change: '-90.34',
        data: [3058, 9000, 10000]
      },
      {
        label: "SPAM",
        backgroundColor: '#BE2D2F',
        percent_change: '-90.34',
        data: [700, 7000, 2000, 7000]
      }
    ]
  }
  })
}
</script>